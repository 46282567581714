import router from './router'
import { setDocumentTitle } from '@/utils/domUtil'
import NProgress from 'nprogress'
import { post } from '@/utils/http'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

/**
 * no redirect whitelist
 * scanSupplierResult：扫描结果
 */
router.beforeEach((to, from, next) => {
    NProgress.start(); // start progress bar
    // console.log(from)
    // console.log(to)
    // console.log(from.name, to.name)

    to.meta && (typeof to.meta.title !== 'undefined' && setDocumentTitle(`${to.meta.title} `));
    if (['home', 'home1', 'home2', 'home3'].includes(to.name)) {
        console.log("1----------");
        // 缓存，10秒内，不需要再重复调用
        // next()
        post(`/api/public/v2/manufacture/tracing/${to.query.traceCode}/findUsedTraceTemplates`, {}, false).then((res) => {
            console.log("3----------");
            if (from.name === 'home' + res.data.sort) {
                next();
                return;
            }
            next({ path: '/home' + res.data.sort, query: { traceCode: to.query.traceCode } });
        }).catch(err => {
            console.log("2----------");
            next();
        });
    }
    if (null === to.name) {
        console.log("触发null");
        next({ path: '/home', query: { traceCode: to.path.replace("/", "") } });
        
    } else {
        next();
    }

    NProgress.done();

})

router.afterEach(() => {
    NProgress.done() // finish progress bar
})
